import { useEffect } from "react";
import Material from "./material";
import Chooseus from "./Chooseus";
import Interiordesign from "./Interiordesign";
import Reviews from "./Reviews";
import Fixed from "./Fixed";
import Achive from "./Achivements";
import { Carousel } from "react-bootstrap";


const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
 
      <section>
        <Carousel controls={false} interval={2000}>
          <Carousel.Item>
            <img
              src="/assets/Newimages/b1.webp"
              alt="img"
              loading="lazy"
              className="img-fluid"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              src="/assets/Newimages/b2.webp"
              alt="img"
              loading="lazy"
              className="img-fluid"
            />
          </Carousel.Item>
        </Carousel>
      </section>
      <Material />
      <Chooseus />
      <Fixed />
      <Interiordesign />
      <Achive />
      <Reviews />
    </>
  );
};

export default Home;
