import Avatar from "antd/es/avatar/avatar";
import React, { useState, useEffect } from "react";

const Reviews = () => {
  const testimonials = [
    {
      text: "I recently purchased a teak wood dining table from Royal Teak, and I must say the craftsmanship is outstanding! The wood quality is premium, and the finish is flawless. It perfectly complements my home interior. Highly recommended teak wood furniture chennai!",
      author: "Arun K",
      image: "assets/person_1.jpg",
    },
    {
      text: "Royal Teak truly lives up to its name! I bought a sofa set, and after months of use, it still looks brand new. The wood is sturdy, and the design is elegant. Worth every penny!",
      author: "Priya S",
      image: "assets/person_2.jpg",
    },
    {
      text: "From the moment I stepped into the showroom to the delivery of my furniture, the experience was seamless. The staff was friendly, and they guided me to choose the best teak wood furniture chennai for my home. I’m very happy with my purchase!",
      author: "Venkatesh R",
      image: "assets/person_3.jpg",
    },
    {
      text: "Finding high-quality Teak wood furniture chennai at a reasonable price was challenging until I came across Royal Teak. Their designs are classy, and the durability is exceptional. Will definitely shop again!",
      author: "Meena D",
      image: "assets/person_3.jpg",
    },
    {
      text: "I was looking for a customized Teak wood furniture chennai wardrobe, and Royal Teak delivered exactly what I had in mind. The detailing is perfect, and the finishing is superb. They exceeded my expectations!",
      author: "Karthik G",
      image: "assets/person_3.jpg",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     handleNext();
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, [currentIndex]);

  const handleCircleClick = (index: React.SetStateAction<number>) => {
    setCurrentIndex(index);
  };

  const { text, author, image } = testimonials[currentIndex];

  return (
    <section>
      <div className="testimonial-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 mx-auto text-center">
              <h2 className="section-title">Testimonials</h2>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="testimonial-slider-wrap text-center">
                <div id="testimonial-nav">
                  <div className="prev" onClick={handlePrev}>
                    <i className="fi fi-rr-angle-small-left icons"></i>
                  </div>
                  <div className="next" onClick={handleNext}>
                    <i className="fi fi-rr-angle-small-right icons"></i>
                  </div>
                </div>

                <div className="testimonial-slider">
                  <div className="item">
                    <div className="row justify-content-center">
                      <div className="col-lg-8 mx-auto">
                        <div className="testimonial-block text-center">
                          <p className="mb-5">
                            <p>&ldquo;{text}&rdquo;</p>
                          </p>

                          <div className="author-info">
                            <div className="author-pic">
                            
                              <Avatar style={{width:"60px",height:"60px",backgroundColor:"#d8cbba",color:"#000",fontWeight:700}}>{author.split("").shift()}</Avatar>
                            </div>
                            <h3 className="font-weight-bold">{author}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Circle Indicators */}
                <div className="circle-indicators">
                  {testimonials.map((_, index) => (
                    <div
                      key={index}
                      className={`circle ${
                        index === currentIndex ? "active" : ""
                      }`}
                      onClick={() => handleCircleClick(index)}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CSS Styles */}
      <style>
        {`
          .testimonial-section {
            padding: 50px 0;
          }
          .testimonial-block {
            padding: 20px;
            border: 1px solid #ddd;
            border-radius: 10px;
            background-color: #f9f9f9;
          }
          .testimonial-slider-wrap {
            position: relative;
          }
          .prev,
          .next {
            cursor: pointer;
            font-size: 24px;
            margin: 10px;
          }
          .circle-indicators {
            display: flex;
            justify-content: center;
            margin-top: 20px;
          }
          .circle {
            width: 10px;
            height: 10px;
            background-color: #ddd;
            border-radius: 50%;
            margin: 0 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;
          }
          .circle.active {
            background-color: #000; /* Active circle color */
          }
        `}
      </style>
    </section>
  );
};

export default Reviews;
