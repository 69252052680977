import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../Home/Home";
import Header from "../Home/Header";
import Footer from "../Footer/Footer";
import Products from "../Products/Products";
import Contactus from "../Contact/Contactus";
import Aboutus from "../Aboutus/Aboutus";

const Routing = () => {
  return (
    <>
      <BrowserRouter>
        <section style={{ width: "100%", height: "67px" }}>
          <Header />
        </section>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/products/:category" element={<Products />} />
          <Route path="/contactus" element={<Contactus />}></Route>
          <Route path="/aboutus" element={<Aboutus />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default Routing;
