import { useState, useEffect } from "react";
import Container from "react-bootstrap/esm/Container";
import { useInView } from "react-intersection-observer";

const Achive = () => {
  const [counts, setCounts] = useState({
    customers: 0,
    products: 0,
    reviews: 0,
  });

  const { ref: sectionRef, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      const duration = 2000;
      const increment = (target: number, key: keyof typeof counts) => {
        let start = 0;
        const stepTime = Math.abs(Math.floor(duration / target));

        const timer = setInterval(() => {
          start += 1;
          setCounts((prevCounts) => ({
            ...prevCounts,
            [key]: start,
          }));
          if (start === target) clearInterval(timer);
        }, stepTime);
      };

      increment(700, "customers");
      increment(500, "products");
      increment(220, "reviews");
    }
  }, [inView]);

  return (
    <>
      <section className="section-bg" ref={sectionRef}>
        <Container>
          <div className="pt-lg-5">
            <h2 className="txt-1-a">Our Achievement</h2>
            <div>
              <div className="row pt-lg-5">
                <div className="rows-combine">
                  <div className="col-lg-4 col-sm-4 col-md-4 mb-4">
                    <div className="achive-bg">
                      <div className="img-contain">
                        <img
                          src="assets/Mask group (4).png"
                          alt="img"
                          className="img-fluid"
                          loading="lazy"
                        />
                      </div>
                      <h1 className="a-txt">{counts.customers}+</h1>
                      <p className="a-txt-1">Customers</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-md-4 mb-4">
                    <div className="achive-bg">
                      <div className="img-contain">
                        <img
                          src="assets/Mask group-1.png"
                          alt="img"
                          className="img-fluid"
                          loading="lazy"
                        />
                      </div>
                      <h1 className="a-txt">{counts.products}+</h1>
                      <p className="a-txt-1">Products</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-4 col-md-4 mb-4">
                    <div className="achive-bg">
                      <div className="img-contain">
                        <img
                          src="assets/Mask group-2.png"
                          alt="img"
                          className="img-fluid"
                          loading="lazy"
                        />
                      </div>
                      <h1 className="a-txt">{counts.reviews}+</h1>
                      <p className="a-txt-1">Reviews</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Achive;
