import React, { useState, useEffect, useRef } from "react";
import { Button, Card, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Modal } from "@mui/material";
import axios from "axios";

interface Deal {
  id: number;
  imageUrl: string;
  product: string;
}

const Products: React.FC = () => {
  const { category } = useParams<{ category: string }>();

  const cardData = [
    {
      imgSrc: "/assets/Newimages/chair/Chair  Besar.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0001",
      name: "Chair Besar",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Antik Cushion.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0002",
      name: "Chair Antik Cushion",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Batavia.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0003",
      name: "Chair Batavia",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Berputar.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0004",
      name: "Chair Berputar",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Bubutan Bulat.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0005",
      name: "Chair Bubutan Bulat",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Cushion Besar.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0006",
      name: "Chair Cushion Besar",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Cushion Jari.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0007",
      name: "Chair Cushion Jari",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Cushion Polos.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0008",
      name: "Chair Cushion Polos",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Cushion Ukir.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH009",
      name: "Chair Cushion Ukir",
    },
    {
      imgSrc: "../../public/assets/Newimages/chair/Chair Cushion.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0010",
      name: "Chair Cushion",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Hiroshima Baru.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0011",
      name: "Chair Hiroshima Baru",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Hiroshima.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0012",
      name: "Chair Hiroshima",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Kevin.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0013",
      name: "Chair Kevin",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Polos.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0014",
      name: "Chair Polos",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Rattan .webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0015",
      name: "Chair Rattan",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Rattan A .webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0016",
      name: "Chair Rattan A",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Rattan Antik.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0017",
      name: "Chair Rattan Antik",
    },
    {
      imgSrc: "../../public/assets/Newimages/chair/Chair Rattan Belagio.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0018",
      name: "Chair Rattan Belagio",
    },

    {
      imgSrc: "/assets/Newimages/chair/Chair Rattan Besar.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0019",
      name: "Chair Rattan Besar",
    },

    {
      imgSrc: "/assets/Newimages/chair/Chair Rattan Bubutan.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0020",
      name: "Chair Rattan Bubutan",
    },

    {
      imgSrc: "/assets/Newimages/chair/Chair Rattan Bulat Tangan.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0021",
      name: "Chair Rattan Bulat Tangan",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Rattan Bulat.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0022",
      name: "Chair Rattan Bulat",
    },

    {
      imgSrc: "/assets/Newimages/chair/Chair Rattan Cushion.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0023",
      name: "Chair Rattan Cushion",
    },

    {
      imgSrc: "/assets/Newimages/chair/Chair Rattan Duduk Cushion.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0024",
      name: "Chair Rattan Duduk Cushion",
    },

    {
      imgSrc: "/assets/Newimages/chair/Chair Rattan Duduk.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0025",
      name: "Chair Rattan Duduk",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Rattan Kotak.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0026",
      name: "Chair Rattan Kotak",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Rattan Lengung.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0027",
      name: "Chair Rattan Lengung",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Rattan Oval.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0028",
      name: "Chair Rattan Oval",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Rattan Polos.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0029",
      name: "Chair Rattan Polos",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Rattan TV.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0030",
      name: "Chair Rattan TV",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Tinggi.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0031",
      name: "Chair Tinggi",
    },
    {
      imgSrc: "/assets/Newimages/chair/Chair Ukir Tinggi.webp",
      offer: "15% off",
      Product: "Chair",
      id: "CH0032",
      name: "Chair Ukir Tinggi",
    },
    //Chair ends

    {
      imgSrc: "/assets/Newimages/buffet/Buffet Credenza.webp",
      offer: "15% off",
      Product: "Buffet Table",
      id: "BF0001",
      name: "Buffet Credenza",
    },
    {
      imgSrc: "/assets/Newimages/buffet/Buffet Minimalis 4 Draws 2 Doors.webp",
      offer: "15% off",
      Product: "Buffet Table",
      id: "BF0002",
      name: "Buffet Minimalis 4 Draws 2 Doors",
    },
    {
      imgSrc: "/assets/Newimages/buffet/Buffet Minimalis Garis.webp",
      offer: "15% off",
      Product: "Buffet Table",
      id: "BF0003",
      name: "Buffet Minimalis Garis",
    },
    {
      imgSrc: "/assets/Newimages/buffet/Buffet Minimalis Polos.webp",
      offer: "15% off",
      Product: "Buffet Table",
      id: "BF0004",
      name: "Buffet Minimalis Polos",
    },
    {
      imgSrc: "/assets/Newimages/buffet/Buffet Table Bunga.webp",
      offer: "15% off",
      Product: "Buffet Table",
      id: "BF0005",
      name: "Buffet Table Bunga",
    },
    {
      imgSrc: "/assets/Newimages/buffet/Buffet Table Butterfly.webp",
      offer: "15% off",
      Product: "Buffet Table",
      id: "BF0006",
      name: "Buffet Table Butterfly",
    },
    {
      imgSrc: "/assets/Newimages/buffet/Buffet Table With Shelf.webp",
      offer: "15% off",
      Product: "Buffet Table",
      id: "BF0007",
      name: "Buffet Table With Shelf",
    },
    //End of buffet table

    {
      imgSrc: "/assets/Newimages/bed/Bed Amora.webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0001",
      name: "Bed Amora",
    },
    {
      imgSrc: "/assets/Newimages/bed/Bed Bagong jari jari .webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0002",
      name: "Bed Bagong Jari Jari",
    },
    {
      imgSrc: "/assets/Newimages/bed/Bed bagong plain.webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0003",
      name: "Bed Bagong Plain",
    },
    {
      imgSrc: "/assets/Newimages/bed/Bed Bamboo.webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0004",
      name: "Bed Bamboo",
    },
    {
      imgSrc: "/assets/Newimages/bed/Bed Batik.webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0005",
      name: "Bed Batik",
    },
    {
      imgSrc: "/assets/Newimages/bed/Bed Canopy.webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0006",
      name: "Bed Canopy",
    },
    {
      imgSrc: "/assets/Newimages/bed/Bed Classic.webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0007",
      name: "Bed Classic",
    },
    {
      imgSrc: "/assets/Newimages/bed/Bed Daun .webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0008",
      name: "Bed Daun",
    },
    {
      imgSrc: "/assets/Newimages/bed/Bed Diamond.webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0009",
      name: "Bed Diamond",
    },
    {
      imgSrc: "/assets/Newimages/bed/Bed Jemani.webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0010",
      name: "Bed Jemani",
    },
    {
      imgSrc: "/assets/Newimages/bed/Bed Kawatan.webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0011",
      name: "Bed Kawatan",
    },
    {
      imgSrc: "/assets/Newimages/bed/Bed Minimalis Daun.webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0012",
      name: "Bed Minimalis Daun",
    },
    {
      imgSrc: "/assets/Newimages/bed/Bed Rahwana .webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0013",
      name: "Bed Rahwana",
    },
    {
      imgSrc: "/assets/Newimages/bed/Bed Rahwana Lengung.webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0014",
      name: "Bed Rahwana Lengung",
    },
    {
      imgSrc: "/assets/Newimages/bed/Bed Rattan Cushion.webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0015",
      name: "Bed Rattan Cushion",
    },
    {
      imgSrc: "/assets/Newimages/bed/Bed Rattan.webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0016",
      name: "Bed Rattan",
    },
    {
      imgSrc: "/assets/Newimages/bed/Bed Sakura Lengung.webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0017",
      name: "Bed Sakura Lengung",
    },

    {
      imgSrc: "/assets/Newimages/bed/Bed Ukir Canopy.webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0018",
      name: "Bed Ukir Canopy",
    },
    {
      imgSrc: "/assets/Newimages/bed/Bed Ukir Mawar.webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0019",
      name: "Bed Ukir Mawar",
    },
    {
      imgSrc: "/assets/Newimages/bed/Bed Ukir.webp",
      offer: "15% off",
      Product: "Cots",
      id: "CT0020",
      name: "Bed Ukir",
    },

    //End of Cots

    {
      imgSrc:
        "/assets/Newimages/display cabinet/Display Cabinet 3 Doors 3 Draws.webp",
      offer: "15% off",
      Product: "Display Cabinet",
      id: "DC0001",
      name: "Display Cabinet 3 Doors 3 Draws",
    },
    {
      imgSrc: "/assets/Newimages/display cabinet/Display Cabinet 3 Doors.webp",
      offer: "15% off",
      Product: "Display Cabinet",
      id: "DC0002",
      name: "Display Cabinet 3 Doors",
    },
    {
      imgSrc: "/assets/Newimages/display cabinet/Display Cabinet Corner.webp",
      offer: "15% off",
      Product: "Display Cabinet",
      id: "DC0003",
      name: "Display Cabinet Corner",
    },
    {
      imgSrc:
        "/assets/Newimages/display cabinet/Display Cabinet Lengung 2 Doors.webp",
      offer: "15% off",
      Product: "Display Cabinet",
      id: "DC0004",
      name: "Display Cabinet Lengung 2 Doors",
    },

    //End of display Cabinet

    {
      imgSrc: "/assets/Newimages/dressing table/Dressing Table Kartini.webp",
      offer: "15% off",
      Product: "Dressing Table",
      id: "DS0001",
      name: "Dressing Table Kartini",
    },
    {
      imgSrc: "/assets/Newimages/dressing table/Dressing Table Lengung.webp",
      offer: "15% off",
      Product: "Dressing Table",
      id: "DS0002",
      name: "Dressing Table Lengung",
    },
    {
      imgSrc:
        "/assets/Newimages/dressing table/Dressing Table Mawar Besar.webp",
      offer: "15% off",
      Product: "Dressing Table",
      id: "DS0003",
      name: "Dressing Table Mawar Besar",
    },
    {
      imgSrc:
        "/assets/Newimages/dressing table/Dressing Table Minimalis 1 Draw.webp",
      offer: "15% off",
      Product: "Dressing Table",
      id: "DS0004",
      name: "Dressing Table Minimalis 1 Draw",
    },
    {
      imgSrc:
        "/assets/Newimages/dressing table/Dressing Table Minimalis 6 Draws.webp",
      offer: "15% off",
      Product: "Dressing Table",
      id: "DS0005",
      name: "Dressing Table Minimalis 6 Draws",
    },
    {
      imgSrc: "/assets/Newimages/dressing table/Dressing Table Minimalis.webp",
      offer: "15% off",
      Product: "Dressing Table",
      id: "DS0006",
      name: "Dressing Table Minimalis",
    },
    {
      imgSrc: "/assets/Newimages/dressing table/Dressing Table Oval.webp",
      offer: "15% off",
      Product: "Dressing Table",
      id: "DS0007",
      name: "Dressing Table Oval",
    },
    {
      imgSrc: "/assets/Newimages/dressing table/Dressing Table Tinggi.webp",
      offer: "15% off",
      Product: "Dressing Table",
      id: "DS0008",
      name: "Dressing Table Tinggi",
    },

    //End of dressing table

    {
      imgSrc: "/assets/Newimages/sofa/Sofa Angsa.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0001",
      name: "Sofa Angsa",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Batavia.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0002",
      name: "Sofa Batavia",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Day Bed Bambu.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0003",
      name: "Sofa Day Bed Bambu",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Day Bed Jari.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0004",
      name: "Sofa Day Bed Jari",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Day Bed Ukir.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0005",
      name: "Sofa Day Bed Ukir",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Diwan Kecil.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0006",
      name: "Sofa Diwan Kecil",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Diwan Lengung.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0007",
      name: "Sofa Diwan Lengung",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Gajah.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0008",
      name: "Sofa Gajah",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Ganesa.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0009",
      name: "Sofa Ganesa",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Grandfather.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0010",
      name: "Sofa Grandfather",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Kerang.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0011",
      name: "Sofa Kerang",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Keranjang.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0012",
      name: "Sofa Keranjang",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa L Diamond.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0013",
      name: "Sofa L Diamond",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa L Jari Jari.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0014",
      name: "Sofa L Jari Jari",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Lengung Jari.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0015",
      name: "Sofa Lengung Jari",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Lengung Polos.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0016",
      name: "Sofa Lengung Polos",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Madura.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0017",
      name: "Sofa Madura",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Minimalis Cushion.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0018",
      name: "Sofa Minimalis Cushion",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Minimalis Daun.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0019",
      name: "Sofa Minimalis Daun",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Minimalis Gajah.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0020",
      name: "Sofa Minimalis Gajah",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Romawi.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0021",
      name: "Sofa Romawi",
    },
    {
      imgSrc: "/assets/Newimages/sofa/Sofa Still Tinggi.webp",
      offer: "15% off",
      Product: "Sofa",
      id: "SF0022",
      name: "Sofa Still Tinggi",
    },

    //end of sofa

    {
      imgSrc: "/assets/Newimages/dining table/Dining Table Balon 6 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0001",
      name: "Dining Table Balon 6 Chairs",
    },
    {
      imgSrc: "/assets/Newimages/dining table/Dining Table Bunga 6 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0002",
      name: "Dining Table Bunga 6 Chairs",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table Bunga Bulat 4 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0003",
      name: "Dining Table Bunga Bulat 4 Chairs",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table Butterfly 6 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0004",
      name: "Dining Table Butterfly 6 Chairs",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table Center Carving 6 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0005",
      name: "Dining Table Center Carving 6 Chairs",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table China 6 Stools .webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0006",
      name: "Dining Table China 6 Stools",
    },
    {
      imgSrc: "/assets/Newimages/dining table/Dining Table Daun 6 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0007",
      name: "Dining Table Daun 6 Chairs",
    },
    {
      imgSrc: "/assets/Newimages/dining table/Dining Table Garis 2 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0008",
      name: "Dining Table Garis 2 Chairs",
    },
    {
      imgSrc: "/assets/Newimages/dining table/Dining Table Garis 4 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0009",
      name: "Dining Table Garis 4 Chairs",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table Hongkong 6 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0010",
      name: "Dining Table Hongkong 6 Chairs",
    },
    {
      imgSrc: "/assets/Newimages/dining table/Dining Table Jambu 4 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0011",
      name: "Dining Table Jambu 4 Chairs",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table Manohara 8 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0012",
      name: "Dining Table Manohara 8 Chairs",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table Manohara Oval 6 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0013",
      name: "Dining Table Manohara Oval 6 Chairs",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table Minimalis Jari 6 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0014",
      name: "Dining Table Minimalis Jari 6 Chairs",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table Minimalis Kotak 4 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0014",
      name: "Dining Table Minimalis Kotak 4 Chairs",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table Minimalis Oval 6 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0015",
      name: "Dining Table Minimalis Oval 6 Chairs",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table Minimalis Sakura 6 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0016",
      name: "Dining Table Minimalis Sakura 6 Chairs",
    },
    {
      imgSrc: "/assets/Newimages/dining table/Dining Table Oval 6 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0017",
      name: "Dining Table Oval 6 Chairs",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table Rattan 4 Chairs 1 Bench.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0018",
      name: "Dining Table Rattan 4 Chairs 1 Bench",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table Rose Bulat 6 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0019",
      name: "Dining Table Rose Bulat 6 Chairs",
    },
    {
      imgSrc: "/assets/Newimages/dining table/Dining Table Round 4 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0020",
      name: "Dining Table Round 4 Chairs",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table Round Cushion 6 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0021",
      name: "Dining Table Round Cushion 6 Chairs",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table Round Polos 6 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0022",
      name: "Dining Table Round Polos 6 Chairs",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table Round Ukir 6 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0023",
      name: "Dining Table Round Ukir 6 Chairs",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table Sakura 6 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0024",
      name: "Dining Table Sakura 6 Chairs",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table Sakura Bulat 4 Chairs.webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0025",
      name: "Dining Table Sakura Bulat 4 Chairs",
    },
    {
      imgSrc:
        "/assets/Newimages/dining table/Dining Table Square 4 Stools .webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0026",
      name: "Dining Table Square 4 Stools",
    },
    {
      imgSrc: "/assets/Newimages/dining table/Dining Table Ukir 6 Chairs .webp",
      offer: "15% off",
      Product: "Dining Table",
      id: "DT0027",
      name: "Dining Table Ukir 6 Chairs",
    },

    //End of dinning table

    {
      imgSrc: "/assets/Newimages/tv tables/TV Table 3 Draws And 2 Doors.webp",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0001",
      name: "TV Table 3 Draws And 2 Doors",
    },
    {
      imgSrc:
        "/assets/Newimages/tv tables/TV Table 3 Draws And 2 Rattan Doors.webp",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0002",
      name: "TV Table 3 Draws And 2 Rattan Doors",
    },
    {
      imgSrc:
        "/assets/Newimages/tv tables/TV Table Minimalis 2 Doors And 1 Draw.webp",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0003",
      name: "TV Table Minimalis 2 Doors And 1 Draw",
    },
    {
      imgSrc: "/assets/Newimages/tv tables/TV Table Minimalis 5 Draws.webp",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0004",
      name: "TV Table Minimalis 5 Draws",
    },
    {
      imgSrc: "/assets/Newimages/tv tables/TV Table Minimalis Polos.webp",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0005",
      name: "TV Table Minimalis Polos",
    },
    {
      imgSrc:
        "/assets/Newimages/tv tables/TV Table Minimalis Sliding Door.webp",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0006",
      name: "TV Table Minimalis Sliding Door",
    },
    {
      imgSrc: "/assets/Newimages/tv tables/TV Table Rattan 2 Draws.webp",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0007",
      name: "TV Table Rattan 2 Draws",
    },
    {
      imgSrc:
        "/assets/Newimages/tv tables/TV Table Rattan 4 Draws And 2 Doors.webp",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0008",
      name: "TV Table Rattan 4 Draws And 2 Doors",
    },
    {
      imgSrc: "/assets/Newimages/tv tables/TV Table Rattan 5 Doors.webp",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0009",
      name: "TV Table Rattan 5 Doors",
    },
    {
      imgSrc: "/assets/Newimages/tv tables/TV Table Ukir 4 Doors.webp",
      offer: "15% off",
      Product: "TV Table",
      id: "TT0010",
      name: "TV Table Ukir 4 Doors",
    },

    //End of tv table

    {
      imgSrc: "/assets/Newimages/wooden statue/Abstract.webp",
      offer: "15% off",
      Product: "Carved Wood",
      id: "CW0001",
      name: "Abstract",
    },
    {
      imgSrc: "/assets/Newimages/wooden statue/Allah Muhammad .webp",
      offer: "15% off",
      Product: "Carved Wood",
      id: "CW0002",
      name: "Allah Muhammad",
    },
    {
      imgSrc: "/assets/Newimages/wooden statue/Ayatul Kursi.webp",
      offer: "15% off",
      Product: "Carved Wood",
      id: "CW0003",
      name: "Ayatul Kursi",
    },
    {
      imgSrc: "/assets/Newimages/wooden statue/Buddha.webp",
      offer: "15% off",
      Product: "Carved Wood",
      id: "CW0004",
      name: "Buddha",
    },
    {
      imgSrc: "/assets/Newimages/wooden statue/Duck.webp",
      offer: "15% off",
      Product: "Carved Wood",
      id: "CW0005",
      name: "Duck",
    },
    {
      imgSrc: "/assets/Newimages/wooden statue/Gajah.webp",
      offer: "15% off",
      Product: "Carved Wood",
      id: "CW0006",
      name: "Gajah",
    },
    {
      imgSrc: "/assets/Newimages/wooden statue/Guitar.webp",
      offer: "15% off",
      Product: "Carved Wood",
      id: "CW0007",
      name: "Guitar",
    },
    {
      imgSrc: "/assets/Newimages/wooden statue/Harley.webp",
      offer: "15% off",
      Product: "Carved Wood",
      id: "CW0008",
      name: "Harley",
    },
    {
      imgSrc: "/assets/Newimages/wooden statue/Kuda Relief.webp",
      offer: "15% off",
      Product: "Carved Wood",
      id: "CW0009",
      name: "Kuda Relief",
    },
    {
      imgSrc: "/assets/Newimages/wooden statue/Kuda.webp",
      offer: "15% off",
      Product: "Carved Wood",
      id: "CW0010",
      name: "Kuda",
    },
    {
      imgSrc: "/assets/Newimages/wooden statue/Rusa Kepala .webp",
      offer: "15% off",
      Product: "Carved Wood",
      id: "CW0011",
      name: "Rusa Kepala",
    },
    {
      imgSrc: "/assets/Newimages/wooden statue/Sepada.webp",
      offer: "15% off",
      Product: "Carved Wood",
      id: "CW0012",
      name: "Sepada",
    },
    {
      imgSrc: "/assets/Newimages/wooden statue/Swan Tray.webp",
      offer: "15% off",
      Product: "Carved Wood",
      id: "CW0013",
      name: "Swan Tray",
    },
    {
      imgSrc: "/assets/Newimages/wooden statue/Tree.webp",
      offer: "15% off",
      Product: "Carved Wood",
      id: "CW0014",
      name: "Tree",
    },

    //end of carved wood

    {
      imgSrc: "/assets/Newimages/swing/Swing Antik.webp",
      offer: "15% off",
      Product: "Swing",
      id: "SW0001",
      name: "Swing Antik",
    },
    {
      imgSrc: "/assets/Newimages/swing/Swing Besar.webp",
      offer: "15% off",
      Product: "Swing",
      id: "SW0002",
      name: "Swing Besar",
    },
    {
      imgSrc: "/assets/Newimages/swing/Swing Gajah.webp",
      offer: "15% off",
      Product: "Swing",
      id: "SW0003",
      name: "Swing Gajah",
    },
    {
      imgSrc: "/assets/Newimages/swing/Swing Jari Jari.webp",
      offer: "15% off",
      Product: "Swing",
      id: "SW0004",
      name: "Swing Jari Jari",
    },
    {
      imgSrc: "/assets/Newimages/swing/Swing Plank.webp",
      offer: "15% off",
      Product: "Swing",
      id: "SW0005",
      name: "Swing Plank",
    },
    {
      imgSrc: "/assets/Newimages/swing/Swing Ukir.webp",
      offer: "15% off",
      Product: "Swing",
      id: "SW0006",
      name: "Swing Ukir",
    },
    {
      imgSrc: "/assets/Newimages/swing/Swing With Roof.webp",
      offer: "15% off",
      Product: "Swing",
      id: "SW0007",
      name: "Swing With Roof",
    },

    // end of swing

    {
      imgSrc: "/assets/Newimages/table with draw/Table 1 Draw Ukir.webp",
      offer: "15% off",
      Product: "Table",
      id: "TA0001",
      name: "Table 1 Draw Ukir",
    },
    {
      imgSrc: "/assets/Newimages/table with draw/Table 1 Draw.webp",
      offer: "15% off",
      Product: "Table",
      id: "TA0002",
      name: "Table 1 Draw",
    },
    {
      imgSrc: "/assets/Newimages/table with draw/Table 2 Draws.webp",
      offer: "15% off",
      Product: "Table",
      id: "TA0003",
      name: "Table 2 Draws",
    },
    {
      imgSrc: "/assets/Newimages/table with draw/Table Bulat Baru.webp",
      offer: "15% off",
      Product: "Table",
      id: "TA0004",
      name: "Table Bulat Baru",
    },
    {
      imgSrc: "/assets/Newimages/table with draw/Table Bulat Jari.webp",
      offer: "15% off",
      Product: "Table",
      id: "TA0005",
      name: "Table Bulat Jari",
    },
    {
      imgSrc: "/assets/Newimages/table with draw/Table Bulat Polos.webp",
      offer: "15% off",
      Product: "Table",
      id: "TA0006",
      name: "Table Bulat Polos",
    },
    {
      imgSrc: "/assets/Newimages/table with draw/Table Console Oval.webp",
      offer: "15% off",
      Product: "Table",
      id: "TA0007",
      name: "Table Console Oval",
    },
    {
      imgSrc: "/assets/Newimages/table with draw/Table Console.webp",
      offer: "15% off",
      Product: "Table",
      id: "TA0007",
      name: "Table Console",
    },
    {
      imgSrc: "/assets/Newimages/table with draw/Table Kaca.webp",
      offer: "15% off",
      Product: "Table",
      id: "TA0007",
      name: "Table Kaca",
    },
    {
      imgSrc: "/assets/Newimages/table with draw/Table Rattan 2 Draws.webp",
      offer: "15% off",
      Product: "Table",
      id: "TA0007",
      name: "Table Rattan 2 Draws",
    },
    {
      imgSrc: "/assets/Newimages/table with draw/Table Rattan Bulat.webp",
      offer: "15% off",
      Product: "Table",
      id: "TA0007",
      name: "Table Rattan Bulat",
    },

    //End of table

    {
      imgSrc: "/assets/Newimages/book rack/Book Rack Ladder.webp",
      offer: "15% off",
      Product: "Book Shelves",
      id: "BS0001",
      name: "Book Rack Ladder",
    },
    {
      imgSrc: "/assets/Newimages/book rack/Bookshelf 4 Draws.webp",
      offer: "15% off",
      Product: "Book Shelves",
      id: "BS0002",
      name: "Bookshelf 4 Draws",
    },
    {
      imgSrc: "/assets/Newimages/book rack/Bookshelf Tinggi.webp",
      offer: "15% off",
      Product: "Book Shelves",
      id: "BS0003",
      name: "Bookshelf Tinggi",
    },
    {
      imgSrc: "/assets/Newimages/book rack/Bookshelf With Door.webp",
      offer: "15% off",
      Product: "Book Shelves",
      id: "BS0004",
      name: "Bookshelf With Door",
    },

    // End of book shelves

    {
      imgSrc: "/assets/Newimages/chest of draws/Chest Of 10 Draws.webp",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0001",
      name: "Chest Of 10 Draws",
    },
    {
      imgSrc: "/assets/Newimages/chest of draws/Chest Of 3 Draws .webp",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0002",
      name: "Chest Of 3 Draws",
    },
    {
      imgSrc: "/assets/Newimages/chest of draws/Chest Of 4 Draws.webp",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0003",
      name: "Chest Of 4 Draws",
    },
    {
      imgSrc:
        "/assets/Newimages/chest of draws/Chest Of 5 Draws Minimalis.webp",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0004",
      name: "Chest Of 5 Draws Minimalis",
    },
    {
      imgSrc: "/assets/Newimages/chest of draws/Chest Of 5 Draws.webp",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0005",
      name: "Chest Of 5 Draws",
    },
    {
      imgSrc: "/assets/Newimages/chest of draws/Chest Of 6 Draws.webp",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0006",
      name: "Chest Of 6 Draws",
    },
    {
      imgSrc: "/assets/Newimages/chest of draws/Chest Of 7 Draws Polos.webp",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0007",
      name: "Chest Of 7 Draws Polos",
    },
    {
      imgSrc: "/assets/Newimages/chest of draws/Chest Of 7 Draws.webp",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0008",
      name: "Chest Of 7 Draws",
    },
    {
      imgSrc: "/assets/Newimages/chest of draws/Chest Of 9 Draws.webp",
      offer: "15% off",
      Product: "Chest of Drawers",
      id: "CD0009",
      name: "Chest Of 9 Draws",
    },
    //End of chest of drawers
    {
      imgSrc: "/assets/Newimages/mirror/Mirror Bulat.webp",
      offer: "15% off",
      Product: "Mirror Frames",
      id: "MF0001",
      name: "Mirror Bulat",
    },
    {
      imgSrc: "/assets/Newimages/mirror/Mirror Bunga.webp",
      offer: "15% off",
      Product: "Mirror Frames",
      id: "MF0002",
      name: "Mirror Bunga",
    },
    {
      imgSrc: "/assets/Newimages/mirror/Mirror Daun.webp",
      offer: "15% off",
      Product: "Mirror Frames",
      id: "MF0003",
      name: "Mirror Daun",
    },
    {
      imgSrc: "/assets/Newimages/mirror/Mirror Leaves.webp",
      offer: "15% off",
      Product: "Mirror Frames",
      id: "MF0004",
      name: "Mirror Leaves",
    },
    {
      imgSrc: "/assets/Newimages/mirror/Mirror Lengung.webp",
      offer: "15% off",
      Product: "Mirror Frames",
      id: "MF0005",
      name: "Mirror Lengung",
    },
    {
      imgSrc: "/assets/Newimages/mirror/Mirror Oval.webp",
      offer: "15% off",
      Product: "Mirror Frames",
      id: "MF0006",
      name: "Mirror Oval",
    },
    {
      imgSrc: "/assets/Newimages/mirror/Mirror Petals.webp",
      offer: "15% off",
      Product: "Mirror Frames",
      id: "MF0007",
      name: "Mirror Petals",
    },
    {
      imgSrc: "/assets/Newimages/mirror/Mirror Rose.webp",
      offer: "15% off",
      Product: "Mirror Frames",
      id: "MF0008",
      name: "Mirror Rose",
    },
    {
      imgSrc: "/assets/Newimages/mirror/Mirror Spring.webp",
      offer: "15% off",
      Product: "Mirror Frames",
      id: "MF0009",
      name: "Mirror Spring",
    },
    {
      imgSrc: "/assets/Newimages/mirror/Mirror Sun Flame.webp",
      offer: "15% off",
      Product: "Mirror Frames",
      id: "MF0010",
      name: "Mirror Sun Flame",
    },
    {
      imgSrc: "/assets/Newimages/mirror/Mirror Sunflower.webp",
      offer: "15% off",
      Product: "Mirror Frames",
      id: "MF0011",
      name: "Mirror Sunflower",
    },
    {
      imgSrc: "/assets/Newimages/mirror/Mirror Tinggi.webp",
      offer: "15% off",
      Product: "Mirror Frames",
      id: "MF0012",
      name: "Mirror Tinggi",
    },
    {
      imgSrc: "/assets/Newimages/mirror/Mirror Twister.webp",
      offer: "15% off",
      Product: "Mirror Frames",
      id: "MF0013",
      name: "Mirror Twister",
    },
    {
      imgSrc: "/assets/Newimages/mirror/Mirror Ukir.webp",
      offer: "15% off",
      Product: "Mirror Frames",
      id: "MF0014",
      name: "Mirror Ukir",
    },
  ];

  // Sample data with multiple images
  const deals: Deal[] = [
    {
      id: 1,
      imageUrl: "",
      product: "Cots",
    },
    {
      id: 2,
      imageUrl: "",
      product: "Sofa",
    },
    {
      id: 3,
      imageUrl: "",
      product: "Chair",
    },
    {
      id: 4,
      imageUrl: "",
      product: "Table",
    },
    {
      id: 5,
      imageUrl: "",
      product: "Book Shelves",
    },
    {
      id: 6,
      imageUrl: "",
      product: "Dressing Table",
    },
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };

  const filteredCardData = category
    ? cardData.filter(
        (card) => card.Product.toLowerCase() === category.toLowerCase()
      )
    : cardData;

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const imageRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const showFullscreen = (index: number) => {
    setCurrentImageIndex(index);
    setIsFullscreen(true);
    if (imageRef.current) {
      if (imageRef.current.requestFullscreen) {
        imageRef.current.requestFullscreen();
      }
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleCloseFullscreen = () => {
    setIsFullscreen(false);
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < filteredCardData.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : filteredCardData.length - 1
    );
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isFullscreen) {
        if (event.key === "ArrowRight") {
          handleNext();
        } else if (event.key === "ArrowLeft") {
          handlePrev();
        } else if (event.key === "Escape") {
          handleCloseFullscreen();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isFullscreen]);

  const handleEnquiryClick = (productId: string) => {
    navigate(`/contactus?productId=${productId}`);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [sendData, setSendData] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [isLoading, setIsloading] = useState(false);

  const handleSave = async () => {
    try {
      if (!sendData.name || !sendData.phone || !sendData.email) {
        message.error("All fields required!");
        return;
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailRegex.test(sendData.email)) {
        message.error("Please enter a valid email address!");
        return;
      }
      setIsloading(true);
      const res = await axios.post(
        "https://royalteakapi.onrender.com/touch",
        sendData
      );
      message.success("Successfully mail sent...");
      setSendData({
        name: "",
        phone: "",
        email: "",
      });
      setIsloading(false);
      handleClose();
    } catch (error) {
      console.log(error);
      message.error("SOmething went wrong!");
    }
  };

  useEffect(() => {
    handleOpen();
  }, []);

  return (
    <section className="pt-3 pb-4 gaps">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center mt-4 p-0">
          <h1 className="product-txt text-start w-100">{category}</h1>
        </div>

        <div className="row gx-4 gy-4 products-row">
          {filteredCardData.length > 0 ? (
            filteredCardData.map((card, index) => (
              <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                <div className="card-gap">
                  <Card
                    className="card-img"
                    cover={
                      <div className="offer-img-product">
                        <div className="d-flex justify-content-center">
                          <div className="card-img-div">
                            <img
                              src={card.imgSrc}
                              alt={card.Product}
                              className="img-fluid"
                              onClick={() => showFullscreen(index)}
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </div>
                    }
                  >
                    {/* Card body content */}
                    <div className="p-3">
                      <p>
                        {card.Product} | ID: {card.id}
                      </p>
                      {/* Button for Enquiry */}
                      <Button
                        className="btn-change"
                        type="link"
                        onClick={() => handleEnquiryClick(card.id)}
                      >
                        Enquiry
                      </Button>
                    </div>
                  </Card>
                </div>
              </div>
            ))
          ) : (
            <p>No products available for the selected category.</p>
          )}
        </div>

        {isFullscreen && (
          <div ref={imageRef} className="fullscreen-image-viewer">
            {/* Left Arrow Button */}
            <Button onClick={handlePrev} className="arr-btns-1">
              <i className="fi fi-rr-angle-small-left"></i>
            </Button>

            {/* Image Center */}
            <div className="img-center">
              <img
                src={filteredCardData[currentImageIndex]?.imgSrc}
                className="img-fluid"
              />
            </div>

            {/* Right Arrow Button */}
            <Button onClick={handleNext} className="arr-btns-2">
              <i className="fi fi-rr-angle-small-right"></i>
            </Button>

            {/* Close Button */}
            <Button onClick={handleCloseFullscreen} className="xx">
              <i className="fi fi-rr-cross-small"></i>
            </Button>
          </div>
        )}
      </div>

      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="model-dfv">
              <div className="d-flex justify-content-between align-items-center">
                <h6>Get more updates</h6>
                <i
                  className="fi fi-rr-circle-xmark"
                  style={{ cursor: "pointer", color: "#201f1f" }}
                  onClick={handleClose}
                ></i>
              </div>
              <label>
                Name <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                type="text"
                className="px-1"
                value={sendData.name}
                onChange={(e) =>
                  setSendData({ ...sendData, name: e.target.value })
                }
                style={{ width: "100%" }}
              />
              <label>
                Phone Number <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                type="text"
                className="px-1"
                value={sendData.phone}
                onChange={(e) =>
                  setSendData({ ...sendData, phone: e.target.value })
                }
                style={{ width: "100%" }}
              />
              <label>
                Email <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <input
                type="text"
                className="px-1"
                value={sendData.email}
                onChange={(e) =>
                  setSendData({ ...sendData, email: e.target.value })
                }
                style={{ width: "100%" }}
              />

              <div className="text-center pt-4">
                {isLoading ? (
                  <Button className="send-btn">Sending...</Button>
                ) : (
                  <Button className="send-btn" onClick={handleSave}>
                    Send
                  </Button>
                )}
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </section>
  );
};

export default Products;
