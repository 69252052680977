import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const Header = () => {
  const [isFeature, setIsFeature] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const handleMenuClick = (key: string) => {
    navigate(`/products/${key}`);
    setIsFeature(false);
    setExpanded(false);
  };

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleFeatureEnter = () => setIsFeature(true);
  const handleFeatureLeave = () => setIsFeature(false);

  return (
    <Navbar
      expand="lg"
      className="bg-body-tertiary navbar"
      fixed="top"
      expanded={expanded}
    >
      <Container className="contain">
        <Navbar.Brand>
          <Link to="/">
            <div className="logo">
              <img
                src="/assets/images/logo (2).png"
                className="img-fluid"
                alt="logo"
                loading="lazy"
              />
            </div>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={handleToggle}
        />
        <Navbar.Collapse id="basic-navbar-nav" className="flex-grow-0">
          <Nav className="me-auto nav-com">
            <Nav.Link
              className="fs-6 text-white fw-600 nav-txt"
              id="home"
              href="/"
              onClick={() => setExpanded(false)}
            >
              <span className="nav-s">Home</span>
            </Nav.Link>

            <NavDropdown
              show={isFeature}
              onMouseEnter={handleFeatureEnter}
              onMouseLeave={handleFeatureLeave}
              title={
                <span
                  style={{
                    color: "#394c6c",
                    fontWeight: "600",
                    paddingBottom: "40px",
                  }}
                >
                  Product Categories
                </span>
              }
              id="basic-nav-dropdown features ser"
              className="fs-6 what"
            >
              <div className="dropdowns-c">
                {/* First div with 6 items */}
                <div>
                  <NavDropdown.Item
                    className="drop"
                    onClick={() => handleMenuClick("Cots")}
                  >
                    Cots
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="pt-2 pb-2 drop"
                    onClick={() => handleMenuClick("Sofa")}
                  >
                    Sofa
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="pt-2 pb-2 drop"
                    onClick={() => handleMenuClick("Chair")}
                  >
                    Chair
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="pt-2 pb-2 drop"
                    onClick={() => handleMenuClick("TV Table")}
                  >
                    TV Table
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="pt-2 pb-2 drop"
                    onClick={() => handleMenuClick("Table")}
                  >
                    Table
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="pt-2 pb-2 drop"
                    onClick={() => handleMenuClick("Display Cabinet")}
                  >
                    Display Cabinet
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="pt-2 pb-2 drop"
                    onClick={() => handleMenuClick("Book Shelves")}
                  >
                    Book Shelves
                  </NavDropdown.Item>
                </div>

                {/* Second div with 5 items */}
                <div>
                  <NavDropdown.Item
                    className="pt-2 pb-2 drop"
                    onClick={() => handleMenuClick("Buffet Table")}
                  >
                    Buffet Table
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="pt-2 pb-2 drop"
                    onClick={() => handleMenuClick("Dressing Table")}
                  >
                    Dressing Table
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="pt-2 pb-2 drop"
                    onClick={() => handleMenuClick("Dining Table")}
                  >
                    Dining Table
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="pt-2 pb-2 drop"
                    onClick={() => handleMenuClick("Carved Wood")}
                  >
                    Carved Wood
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="pt-2 pb-2 drop"
                    onClick={() => handleMenuClick("Swing")}
                  >
                    Swing
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="pt-2 pb-2 drop"
                    onClick={() => handleMenuClick("Chest of Drawers")}
                  >
                    Chest of Drawers
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="pt-2 pb-2 drop"
                    onClick={() => handleMenuClick("Mirror Frames")}
                  >
                    Mirror Frames
                  </NavDropdown.Item>
                </div>
              </div>
            </NavDropdown>

            <Nav.Link
              className="fs-6 text-white fw-400 nav-txt"
              id="privacy"
              href="/aboutus"
              onClick={() => setExpanded(false)}
            >
              <span className="nav-s"> About us</span>
            </Nav.Link>
            <Nav.Link
              className="fs-6 text-white fw-400 nav-txt"
              id="privacy"
              href="/Contactus"
              onClick={() => setExpanded(false)}
            >
              <span className="nav-s"> Contact</span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
